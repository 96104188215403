export const environment = {
  production: false,
  online_test_baseIP: "https://iglobeback.bentraytech.com/",
  online_test_apiPrefix: "api/web/v1/",
  online_test_defaultImagePath: "./assets/dist/img",
  online_test_baseImageUrl: "https://iglobeback.bentraytech.com/",
  online_test_api_key: "KEY012346",

  // this is iglobe configuration..

  // production: false,
  // online_test_baseIP: "https://onlinetestbackend.iglobefdn.com/",
  // online_test_apiPrefix: "api/web/v1/",
  // online_test_defaultImagePath: "./assets/dist/img",
  // online_test_baseImageUrl: "https://onlinetestbackend.iglobefdn.com/",
  // online_test_api_key: "KEY012346",
};
